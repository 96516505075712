import { useEffect, useRef, EffectCallback } from 'react';

import { createCustomEqual, EqualityComparatorCreator } from 'fast-equals';

import { isLatLngLiteral } from '@googlemaps/typescript-guards';

const deepEqualCustom: EqualityComparatorCreator<undefined> =
  deepEqual => (a, b) => {
    // Verifica se ambos são nulos ou indefinidos
    if (a == null && b == null) return true;

    // Se apenas um deles for nulo ou indefinido, são diferentes
    if (a == null || b == null) return false;

    // Verifica se são objetos LatLng ou LatLngLiteral
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      try {
        const latLngA =
          a instanceof google.maps.LatLng ? a : new google.maps.LatLng(a);
        const latLngB =
          b instanceof google.maps.LatLng ? b : new google.maps.LatLng(b);
        return latLngA.equals(latLngB);
      } catch (error) {
        console.error('Error comparing LatLng objects:', error);
        return false;
      }
    }

    // Verifica se são objetos do Google Maps com propriedades específicas
    if (a.lat && a.lng && b.lat && b.lng) {
      try {
        const latA = typeof a.lat === 'function' ? a.lat() : a.lat;
        const lngA = typeof a.lng === 'function' ? a.lng() : a.lng;
        const latB = typeof b.lat === 'function' ? b.lat() : b.lat;
        const lngB = typeof b.lng === 'function' ? b.lng() : b.lng;

        return latA === latB && lngA === lngB;
      } catch (error) {
        console.error('Error comparing lat/lng objects:', error);
        return false;
      }
    }

    // Usa fast-equals para outros objetos
    return deepEqual(a, b);
  };

const deepEqualsForMaps = createCustomEqual(() => ({
  createIsNestedEqual: deepEqualCustom,
}));

function useDeepCompareMemoize(value: any) {
  const ref = useRef();

  if (!deepEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffectForMaps(
  callback: EffectCallback,
  dependencies: any[],
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
