import React, { useCallback, useState, useEffect } from 'react';
import { ColumnProps } from 'antd/lib/table';

import { Menu, PaginationProps, Progress, Tag } from 'antd';

import {
  BarChartOutlined,
  DownloadOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { TableContent, TableGroup2 } from './styles';
import { useCustomTranslation } from '../../../hooks/custom-translation';
import { useDeleteFileImportMutation } from '../../../services/fileImporter';
import ModalExportFile from '../ModalExportFile';
import FormInput from '../../FormInput';
import ModalDelete from '../../ModalDelete';
import { ShowFormMessage } from '../../../helpers/message';
import getErrors from '../../../helpers/errors';
import DescriptiveAnalysisModal from '../../DescriptiveAnalysisModal';
import ModalEnrichmentDetailsPage from '../../../pages/ContactImportWizard/ModalEnrichmentDetails';
import { EnrichmentProps } from '../../TabGroupEnrichmentDetails/Enrichment';
import { QualitiesProps } from '../../TabGroupEnrichmentDetails/Sanitization';
import { usePostFileExportMutation } from '../../../services/fileExport';
import FilterIcon from '../../FilterIcon';
// import {
//   useGetImportRfvFileQuery,
//   useGetPresignedUrlRfvQuery,
// } from '../../../services/importRfv';
import { Body } from '../../Dropdown/styles';
import { HelperDate } from '../../../helpers/date';
import { ActionButton, ActionTab, ReportButton } from '../styles';
import * as CONTANTS from '../../../navigation/constants';
import Dropdown from '../../Dropdown';
import Button from '../../Button';
import {
  useGetImportRfvFileQuery,
  useGetPresignedUrlRfvQuery,
} from '../../../services/importRfv';

const ImportsTabRfv = ({
  setLoader,
  refetch,
}: {
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: boolean;
}) => {
  const [fileImportId, setFileImportId] = useState<string>('');
  const [fileImportSource] = useState<string>('');
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalAnalysisOpen, setIsModalAnalysisOpen] = useState(false);
  const [customerFileName, setCustomerFileName] = useState<string>('');
  const [qualities] = useState<QualitiesProps>();
  const [enrichment, setEnrichment] = useState<EnrichmentProps>();
  const [isModalEnrichmentOpen, setIsModalEnrichmentOpen] =
    useState<boolean>(false);
  const [nameFile, setNameFile] = useState('');

  const [deleFileImport, configDeleFileImport] = useDeleteFileImportMutation();

  const [page, setPage] = React.useState(1);

  const [search, setSearch] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState<number>(
    window.innerHeight < 900 ? 7 : 9,
  );
  const [, setFetchInterval] = useState<number>(2000);

  const handleChangeRowsPerPage: PaginationProps['onShowSizeChange'] = (
    current,
    pageSize,
  ) => {
    setRowsPerPage(pageSize);
  };
  const navigate = useNavigate();

  const {
    data: files,
    refetch: filesRefetch,
    isLoading: filesLoading,
  } = useGetImportRfvFileQuery({
    top: rowsPerPage,
    skip: page,
  });

  const [postFileExport, configPostFileExport] = usePostFileExportMutation();

  const { tContactManagement } = useCustomTranslation(
    'contactManagement',
    'commonWords',
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModalExportFile = useCallback(() => {
    setIsModalOpen(false);
    setFileImportId('');
  }, []);

  const handleCloseModalDelete = useCallback(() => {
    setIsModalDeleteOpen(false);
  }, []);

  const handleDeleteImport = async () => {
    await deleFileImport(fileImportId).unwrap();
  };

  const handleCloseModalAnalysis = useCallback(() => {
    setIsModalAnalysisOpen(false);
    setFileImportId('');
  }, []);

  const updateReport = () => {
    setIsModalEnrichmentOpen(false);
    setIsModalEnrichmentOpen(true);
  };

  const handleCloseModalEnrichment = () => {
    setIsModalEnrichmentOpen(false);
    setFileImportId('');
  };

  const showMessageDeleteFileImporter = useCallback(() => {
    const { isSuccess, isError, error } = configDeleFileImport;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }
    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Importação deletada com sucesso',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
    if (isSuccess) {
      setIsModalDeleteOpen(false);
    }
  }, [configDeleFileImport]);

  const handleSubmit = async () => {
    await postFileExport({
      dataSourceId: fileImportId,
      dataSource: fileImportSource,
      fileExportTypeId: 2,
    });
    handleCloseModalEnrichment();
  };

  const showMessagePostFileExport = useCallback(() => {
    const { isSuccess, isError, error } = configPostFileExport;

    let errors: any;

    if (error) {
      const { data: errorData }: any = error;
      errors = errorData;
    }

    ShowFormMessage.SuccessOrError({
      status: {
        isSuccess,
        isError,
      },
      config: {
        successMessage: 'Seu arquivo foi gerado e está sendo processado.',
        errorMessage: error ? getErrors(errors.errors) : '',
      },
    });
  }, [configPostFileExport]);

  const {
    data,
    isSuccess,
    refetch: fileRefetch,
  } = useGetPresignedUrlRfvQuery({
    fileName: nameFile,
  });

  useEffect(() => {
    if (refetch) fileRefetch();
  }, [refetch]);

  useEffect(() => {
    if (isSuccess && data?.url !== '') {
      window.location = data?.url;
    }
  }, [isSuccess]);

  const MenuDropdown = ({ name }: any) => (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Button
              type="link"
              onClick={() => setNameFile(name)}
              icon={<DownloadOutlined />}
            >
              {tContactManagement('download_analysis')}
            </Button>
          ),
        },
      ]}
    />
  );

  useEffect(() => {
    const getPositionById = (id: string) => {
      let position = 0;
      files.forEach((item: any, i: any) => {
        if (item.id === id) position = i;
      });
      return position;
    };
    if (files?.length) {
      const positionId = getPositionById(fileImportId);
      setCustomerFileName(files[positionId].name || '');

      const tempEnrichment = files?.map((item: any) => ({
        key: item.id,
        infoValue: item.infoValue
          ? item.infoValue.charAt(0) + item.infoValue.slice(1).toLowerCase()
          : '',
        infoName: item.infoName
          ? item.infoName.charAt(0) + item.infoName.slice(1).toLowerCase()
          : '',
        ...item,
      }));

      setEnrichment(tempEnrichment);
      // setQualities(null);
    }
  }, [fileImportId, files, isModalEnrichmentOpen, isModalOpen]);

  useEffect(showMessagePostFileExport, [showMessagePostFileExport]);

  useEffect(showMessageDeleteFileImporter, [showMessageDeleteFileImporter]);

  useEffect(() => {
    const unreadyFiles = files?.data?.filter(
      (file: any) =>
        file.fileImportStatusId === 1 ||
        file.fileImportStatusId === 3 ||
        file.fileImportStatusId === 4,
    );

    if (unreadyFiles?.length) {
      return setFetchInterval(120000);
    }

    return setFetchInterval(0);
  }, [files?.data]);

  useEffect(() => {
    if (refetch) {
      filesRefetch();
    }
  }, [refetch]);

  useEffect(() => {
    if (filesLoading) {
      return setLoader(true);
    }

    return setLoader(false);
  }, [filesLoading]);

  const tagColor = ['warning', 'success', 'error'];

  const handleInputSearch = (e: any) => {
    setSearch(e.target.value);
  };
  const handleRfvAnalysis = (record: any) => {
    navigate(`${CONTANTS.RFV}/${record.id}`);
  };

  const columnsTableGroup2: ColumnProps<any>[] = [
    {
      title: `${tContactManagement('source_file')}`,
      dataIndex: 'name',
      align: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterDropdown: () => (
        <FormInput
          placeholder={tContactManagement('name')}
          onChange={handleInputSearch}
        />
      ),
      filterIcon: () => <FilterIcon isFiltered={!!search} />,
    },
    {
      title: `${tContactManagement('date')}`,
      dataIndex: 'createdAt',
      align: 'left',
      width: '216px',

      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      render: value => {
        return <Body>{HelperDate.formateDate(value)}</Body>;
      },
    },
    {
      title: 'Progresso',
      dataIndex: 'conclusionPercentage',
      align: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (record: any) => {
        return (
          <>
            <Progress percent={record} size="small" status="active" />
          </>
        );
      },
    },
    {
      title: `${tContactManagement('status')}`,
      dataIndex: 'statusName',
      align: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, record: any) => {
        const statusIndex =
          // eslint-disable-next-line no-nested-ternary
          record.statusId === 1 ? 0 : record.statusId === 2 ? 1 : 2;
        return <Tag color={tagColor[statusIndex]}>{record.statusName}</Tag>;
      },
    },
    {
      title: `${tContactManagement('actions')}`,
      dataIndex: 'acoes',
      align: 'center',
      width: '246px',
      render: (_, record: any) => {
        return (
          <ActionTab>
            <>
              <ReportButton
                type="default"
                icon={<BarChartOutlined />}
                disabled={record.statusId !== 2}
                onClick={() => {
                  handleRfvAnalysis(record);
                }}
              >
                {tContactManagement('analysis_rfv')}
              </ReportButton>
              <Dropdown overlay={<MenuDropdown name={record.name} />}>
                <ActionButton type="default" icon={<MoreOutlined />} />
              </Dropdown>
            </>
          </ActionTab>
        );
      },
    },
  ];

  return (
    <TableContent>
      <TableGroup2
        dataSource={files}
        columns={columnsTableGroup2}
        rowKey="id"
        pagination={{
          defaultPageSize: rowsPerPage,
          onShowSizeChange: handleChangeRowsPerPage,
          total: files?.meta?.size,
          onChange: currentPage => setPage(currentPage),
        }}
      />
      <ModalExportFile
        fileImportId={fileImportId}
        fileImportSource={fileImportSource}
        visible={isModalOpen}
        onCancel={handleCloseModalExportFile}
        onOk={handleCloseModalExportFile}
        customerFileName={customerFileName}
      />
      <ModalDelete
        visible={isModalDeleteOpen}
        onCancel={handleCloseModalDelete}
        title={`${tContactManagement('delete_file')}`}
        deleteButton={`${tContactManagement('delete_file')}`}
        onOk={handleDeleteImport}
      >
        Ao executar a exclusão, todos os registros deste arquivo serão removidos
        do sistema.
      </ModalDelete>
      <DescriptiveAnalysisModal
        fileId={fileImportId}
        fileTypeDataSource={fileImportSource}
        visible={
          fileImportId !== '' && fileImportSource !== '' && isModalAnalysisOpen
        }
        onCancel={handleCloseModalAnalysis}
      />

      {qualities && enrichment && (
        <ModalEnrichmentDetailsPage
          visible={isModalEnrichmentOpen}
          customerFilename={customerFileName}
          qualities={qualities}
          enrichment={enrichment}
          onUpdate={updateReport}
          onOk={handleSubmit}
          onCancel={handleCloseModalEnrichment}
        />
      )}
    </TableContent>
  );
};

export default ImportsTabRfv;
