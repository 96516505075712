import {
  useContext,
  createContext,
  useState,
  FC,
  PropsWithChildren,
  useRef,
  useCallback,
  useEffect,
} from 'react';

interface MapsContextData {
  pin?: google.maps.LatLng;
  setPin(pin?: google.maps.LatLng): void;
  center?: google.maps.LatLngLiteral;
  setCenter(initialCenter: google.maps.LatLngLiteral): void;
  radius: number;
  setRadius(radius: number): void;
  placeSelected?: google.maps.places.PlaceResult;
  setPlaceSelected(place: google.maps.places.PlaceResult): void;
  getCurrentPosition(
    setCurrentPosition: (position: google.maps.LatLngLiteral) => void,
  ): void;
  getFirstStreetAddressByLocation(
    location: google.maps.LatLng,
  ): Promise<google.maps.GeocoderResult>;
  clearMapState(): void;
}

const RADIUS = 0;

const MapsContext = createContext<MapsContextData>({} as MapsContextData);

const MapsProvider: FC<PropsWithChildren> = ({ children }) => {
  const geocoderRef = useRef<google.maps.Geocoder | null>(null);

  // Inicializa o geocoder quando o componente é montado
  useEffect(() => {
    if (window.google && window.google.maps && !geocoderRef.current) {
      geocoderRef.current = new google.maps.Geocoder();
    }
  }, []);

  const [pin, setPin] = useState<google.maps.LatLng>();
  const [center, setCenter] = useState<google.maps.LatLngLiteral>();
  const [radius, setRadius] = useState<number>(RADIUS);
  const [placeSelected, setPlaceSelected] =
    useState<google.maps.places.PlaceResult>();

  // Função para limpar o estado do mapa
  const clearMapState = useCallback(() => {
    setPin(undefined);
    setPlaceSelected(undefined);
  }, []);

  // Sincroniza o pin quando o placeSelected muda
  useEffect(() => {
    if (
      placeSelected &&
      placeSelected.geometry &&
      placeSelected.geometry.location &&
      (!pin ||
        (pin && placeSelected.geometry.location.lat() !== pin.lat()) ||
        placeSelected.geometry.location.lng() !== pin.lng())
    ) {
      setPin(placeSelected.geometry.location);
    }
  }, [placeSelected, pin]);

  const getCurrentPosition = useCallback(
    (setCurrentPosition: { (position: google.maps.LatLngLiteral): void }) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          ({ coords: { latitude, longitude } }: GeolocationPosition) => {
            setCurrentPosition({
              lat: latitude,
              lng: longitude,
            });
          },
          error => {
            console.error('Error getting current position:', error);
            // Fallback para uma posição padrão
            setCurrentPosition({
              lat: -23.5505, // São Paulo
              lng: -46.6333,
            });
          },
          { timeout: 10000, enableHighAccuracy: true },
        );
      } else {
        // Fallback para uma posição padrão se geolocalização não estiver disponível
        setCurrentPosition({
          lat: -23.5505, // São Paulo
          lng: -46.6333,
        });
      }
    },
    [],
  );

  const getPlacesByLocation = useCallback(
    (location: google.maps.LatLng): Promise<google.maps.GeocoderResponse> => {
      if (!geocoderRef.current) {
        geocoderRef.current = new google.maps.Geocoder();
      }
      return new Promise((resolve, reject) => {
        geocoderRef.current?.geocode({ location }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results) {
            resolve({ results });
          } else {
            reject(new Error(`Geocoder failed: ${status}`));
          }
        });
      });
    },
    [],
  );

  const getFirstStreetAddressByPlaces = useCallback(
    (places: google.maps.GeocoderResponse) => {
      return (
        places.results.find(place => place.types.includes('street_address')) ||
        places.results[0]
      );
    },
    [],
  );

  const getFirstStreetAddressByLocation = useCallback(
    async (location: google.maps.LatLng) => {
      try {
        const places = await getPlacesByLocation(location);
        return getFirstStreetAddressByPlaces(places);
      } catch (error) {
        console.error('Error getting address by location:', error);
        throw error;
      }
    },
    [getFirstStreetAddressByPlaces, getPlacesByLocation],
  );

  return (
    <MapsContext.Provider
      value={{
        pin,
        setPin,
        center,
        setCenter,
        radius,
        setRadius,
        placeSelected,
        setPlaceSelected,
        getCurrentPosition,
        getFirstStreetAddressByLocation,
        clearMapState,
      }}
    >
      {children}
    </MapsContext.Provider>
  );
};

function useMaps(): MapsContextData {
  const context = useContext(MapsContext);

  if (!context) {
    throw new Error('useMaps must be used within an MapsProvider');
  }

  return context;
}

export { MapsProvider, useMaps };
