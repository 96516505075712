import React, { memo, useCallback, useEffect } from 'react';

import { useDrop } from 'react-dnd';
import Circle from '../../../components/GoogleMaps/Circle';
import CustomMap from '../../../components/GoogleMaps/CustomMap';
import Marker from '../../../components/GoogleMaps/Marker';

import { useMaps } from '../../../hooks/maps';

import { Container } from './styles';

interface IProps {
  setPosX: React.Dispatch<React.SetStateAction<number>>;
  setPosY: React.Dispatch<React.SetStateAction<number>>;
  data: any;
  preview?: boolean;
}

interface DragItem {
  posX: number;
  posY: number;
}

const Map = ({ setPosX, setPosY, data, preview = false }: IProps) => {
  const {
    pin,
    setPin,
    center,
    radius,
    setCenter,
    getCurrentPosition,
    setPlaceSelected,
    getFirstStreetAddressByLocation,
    placeSelected,
  } = useMaps();

  // const [zoom, setZoom] = useState<number>(5);

  const [, drop] = useDrop(
    () => ({
      accept: 'box',
      drop(item: DragItem, monitor) {
        const clientX = monitor.getClientOffset()?.x as number;
        const clientY = monitor.getClientOffset()?.y as number;
        setPosX(clientX - 159);
        setPosY(clientY - 90);
        return undefined;
      },
    }),
    [],
  );

  const onClick = useCallback(
    async ({ latLng }: google.maps.MapMouseEvent) => {
      if (latLng) {
        setPin(latLng);
        try {
          setPlaceSelected(await getFirstStreetAddressByLocation(latLng));
        } catch (error) {
          console.error('Error getting address from location:', error);
        }
      }
    },
    [getFirstStreetAddressByLocation, setPin, setPlaceSelected],
  );

  // Efeito para atualizar o pin quando placeSelected mudar
  useEffect(() => {
    if (
      placeSelected &&
      placeSelected.geometry &&
      placeSelected.geometry.location
    ) {
      // Atualiza o pin com a localização do lugar selecionado
      setPin(placeSelected.geometry.location);

      // Centraliza o mapa na localização do pin
      if (
        placeSelected.geometry.location.lat &&
        placeSelected.geometry.location.lng
      ) {
        setCenter({
          lat: placeSelected.geometry.location.lat(),
          lng: placeSelected.geometry.location.lng(),
        });
      }
    }
  }, [placeSelected, setPin, setCenter]);

  useEffect(() => {
    if (preview) {
      setCenter({ lat: -13.14, lng: -58.4 });
    } else {
      getCurrentPosition(setCenter);
    }
  }, [getCurrentPosition, preview, setCenter]);

  return (
    <Container ref={drop}>
      <CustomMap
        center={center}
        onClick={onClick}
        heatMapGeoHashes={data}
        zoom={preview ? 5 : 14}
        minZoom={3}
        isFractionalZoomEnabled
      >
        {pin && <Marker position={pin} />}
        {pin && <Circle center={pin} radius={radius} />}
      </CustomMap>
    </Container>
  );
};

export default memo(Map);
