import { memo, useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

import { CloseCircleFilled } from '@ant-design/icons';
import { FormInstance, Rule } from 'antd/lib/form';

import { useDeepCompareEffectForMaps } from '../../../helpers/maps/use-deep-compare';

import { Input, InputContainer } from './styles';

export type PlaceResult = google.maps.places.PlaceResult;

interface Props extends google.maps.places.AutocompleteOptions {
  label?: string;
  name: string;
  rules?: Rule[];
  form: FormInstance;
  value: string;
  setPlace(place: PlaceResult): void;
}

const Autocomplete: React.FC<Props> = ({
  name,
  form,
  setPlace,
  value,
  ...options
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [input, setInput] = useState<google.maps.places.Autocomplete>();
  const [inputError, setInputError] = useState<boolean>(false);
  const [alreadyPlace, setAlreadyPlace] = useState<boolean>(false);
  const autocompleteListenerRef = useRef<google.maps.MapsEventListener | null>(
    null,
  );

  const onSelect = useCallback(
    (place: PlaceResult) => {
      if (!place || !Object.keys(place).length) {
        form.setFieldsValue({ [name]: '' });
        setPlace({});
        setInputError(false);
        setAlreadyPlace(false);
        return;
      }

      const address = place.formatted_address ?? place.name ?? '';
      form.setFieldsValue({ [name]: address });
      setPlace(place);
      setInputError(false);
      setAlreadyPlace(!!address);
    },
    [form, name, setPlace],
  );

  const onErase = useCallback(() => {
    onSelect({});
  }, [onSelect]);

  const debouncedHandleChangeInput = useCallback(
    debounce(async () => {
      if (alreadyPlace) {
        onErase();
        try {
          await form.validateFields([name]);
        } catch {
          setInputError(true);
        }
      }
    }, 300),
    [alreadyPlace, form, name, onErase],
  );

  const handleChangeInput = useCallback(() => {
    debouncedHandleChangeInput();
  }, [debouncedHandleChangeInput]);

  const handleChangePlace = useCallback(() => {
    if (input) {
      try {
        const place = input.getPlace();
        if (place && (place.formatted_address || place.name)) {
          onSelect(place);
          setInputError(false);
        } else {
          setInputError(true);
        }
      } catch (error) {
        console.error('Error getting place:', error);
        setInputError(true);
      }
    }
  }, [input, onSelect]);

  const handleClear = useCallback(() => {
    if (ref.current) {
      ref.current.value = '';
    }
    form.setFieldsValue({ [name]: '' });
    onErase();
  }, [form, name, onErase]);

  useEffect(() => {
    if (ref.current && !input && window.google && window.google.maps) {
      try {
        const autocomplete = new window.google.maps.places.Autocomplete(
          ref.current,
          {
            componentRestrictions: { country: 'br' },
            fields: [
              'address_components',
              'geometry',
              'name',
              'formatted_address',
            ],
          },
        );

        setInput(autocomplete);
      } catch (error) {
        console.error('Error initializing autocomplete:', error);
      }
    }
  }, [ref, input]);

  useDeepCompareEffectForMaps(() => {
    if (input) {
      input.setOptions(options);
    }
  }, [input, options]);

  useEffect(() => {
    if (input) {
      if (autocompleteListenerRef.current) {
        google.maps.event.removeListener(autocompleteListenerRef.current);
        autocompleteListenerRef.current = null;
      }

      autocompleteListenerRef.current = input.addListener(
        'place_changed',
        handleChangePlace,
      );
    }

    return () => {
      if (autocompleteListenerRef.current) {
        google.maps.event.removeListener(autocompleteListenerRef.current);
        autocompleteListenerRef.current = null;
      }
      debouncedHandleChangeInput.cancel();
    };
  }, [input, handleChangePlace, debouncedHandleChangeInput]);

  useEffect(() => {
    if (ref.current) {
      if (value) {
        ref.current.value = value;
      } else {
        ref.current.value = '';
      }
    }
  }, [value]);

  return (
    <InputContainer status={inputError && alreadyPlace ? 'error' : 'default'}>
      <Input>
        <input
          ref={ref}
          onChange={handleChangeInput}
          placeholder="Digite um endereço"
        />
        <CloseCircleFilled onClick={handleClear} />
      </Input>
    </InputContainer>
  );
};

export default memo(Autocomplete);
